<template >
  <div class="table-wrap">
    <div class="table-container" style="margin-bottom:24px;">
      <div class="tableHeadFilter" v-if="rows">
        <div class="row" :class="{ active: areaMore }">
          <div class="label">地区范围:</div>
          <div class="filters">
            <div class="filter" :class="{ active: item.name == filter.area.name }" v-for="(item, idx) in rows.area"
              :key="idx" @click="filter.area = item;filter.dept='全部';filter.industry = '全部';filter.tag = '全部'; handleSearch(); getOptions(item);">{{ item.name }}</div>
          </div>
          <div class="fixelBtn" @click="areaMore = !areaMore;">
            <span>更多</span>
            <CaretDownOutlined :rotate="areaMore ? -90 : 0" />
          </div>
        </div>
        <div class="row" :class="{ active: deptMore }">
          <div class="label">信息来源:</div>
          <div class="filters">
            <div class="filter" :class="{ active: item == filter.dept }" v-for="(item, idx) in rows.dept" :key="idx"
              @click="filter.dept = item; filter.industry = '全部';filter.tag = '全部';handleSearch();">{{ item }}</div>
          </div>
          <div class="fixelBtn" @click="deptMore = !deptMore;">
            <span>更多</span>
            <CaretDownOutlined :rotate="deptMore ? -90 : 0" />
          </div>
        </div>
        <div class="row" :class="{ active: industryMore }">
          <div class="label">信息类型:</div>
          <div class="filters">
            <div class="filter" :class="{ active: item == filter.zclx }" v-for="(item, idx) in rows.zclx"
              :key="idx" @click="filter.zclx = item;filter.tag = '全部'; handleSearch();">{{ item }}</div>
          </div>
          <div class="fixelBtn" @click="industryMore = !industryMore;">
            <span>更多</span>
            <CaretDownOutlined :rotate="industryMore ? -90 : 0" />
          </div>
        </div>
        <div class="row" :class="{ active: tagMore }">
          <div class="label">产业类别:</div>
          <div class="filters">
            <div class="filter" :class="{ active: item == filter.tag }" v-for="(item, idx) in rows.tag" :key="idx"
              @click="filter.tag = item; handleSearch();">{{ item }}</div>
          </div>
          <div class="fixelBtn" @click="tagMore = !tagMore;">
            <span>更多</span>
            <CaretDownOutlined :rotate="tagMore ? -90 : 0" />
          </div>
        </div>
        <div class="search">
          <a-input size="large" @change="handleInputSearch" allow-clear class="filter" placeholder="请输入政策关键字"
            style="width:360px;" v-model:value="filter.keyword"></a-input>
          <a-button size="large" class="filter-btn" type="primary" @click="handleSearch">搜索</a-button>
          <a-button size="large" class="filter-btn" type="primary" @click="toPp">政策匹配</a-button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table">
        <a-table size="middle" class="tableRef" :columns="columns" :row-key="record => record.id"
          :data-source="dataSource" :pagination="pagination" :loading="loading" @change="handleTableChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'sort_num'">
              <div class="drapHandle" style="cursor: pointer;">
                <holder-outlined />
                <span style="margin-left:6px;">{{ record.sort_num }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'title'">
              <div class="data-title" @click="toUrl(record)">
                <a-tooltip placement="topLeft" :title="record.title" :color="'geekblue'">
                  <span>{{ record.title }}</span>
                </a-tooltip>
              </div>
            </template>


          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
// import { message, Modal } from 'ant-design-vue';
import { HolderOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
import { mapState } from 'vuex';

export default defineComponent({
  components: {
    HolderOutlined,
    CaretDownOutlined
  },

  data() {
    return {
      currentTab: '',
      filter: {
        ...this.initFilter,
        keyword: '',
      },
      current: null,
      rows: null,
      tagMore: false,
      deptMore: false,
      industryMore: false,
      areaMore: false,
    }
  },
  watch: {
  },
  mounted() {
    this.getOptions();
  },
  computed: {
    ...mapState(['userinfo']),
  },
  methods: {
    toPp() {
      if (this.userinfo)
        this.$router.push('/work/home/company-match')
      else {
        this.$router.replace({
          path: '/login',
          query: {
            rewitchPath: '/work/home/company-match'
          }
        })
      }
    },
    handleInputSearch(e) {
      if (!e.target.value) {
        this.handleSearch();
      }
    },
    toUrl(item) {
      //window.open(item.url)
      this.$router.push('/prev/policyDetail?id='+item.id)
    },
    async getOptions(area = undefined) {
      let res = await service.get_search_condition({ area });
      //let industry = await service.get_item_lists();
      let tag = await service.get_trade_lists();
      this.rows = {
        ...res.data,
        area: res.data.area.filter(f => f),
        dept: res.data.dept.filter(f => f),
        zclx: ['全部','申报通知','立项批文','奖励文件','信息资讯'],
        tag: ['全部'].concat(tag.data?.map?.(m => m.name) || []),
      };
    },
    // 切换页码调用获取
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    // 筛选
    handleSearch() {
      this.run({
        //page: this.pagination.current,
        page: 1,
        limit: this.pagination.pageSize,
        ...this.filter
      })
    },

  },
  setup() {
    // 声明并调用一次数据拉取
    let initFilter = {
      area: {
        name: '全部',
        level: '0'
      },
      dept: '全部',
      zclx: '全部',
      tag: '全部',
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination((params) => service.get_article_lists_work({ ...initFilter, ...params }), {
      formatResult: res => {
        res.data.total_num = res.total_number;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      initFilter,
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'sort_num',
        // },
        {
          title: '资讯名称',
          dataIndex: 'title',
          width: 900,
          align:'left'
        },
        {
          title: '信息来源',
          dataIndex: 'dept_name',
          align:'center'
        },
        {
          title: '发布日期',
          dataIndex: 'release_time',
          align:'center'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped>
.table-wrap {
  margin: 24px auto;
  width: 1400px;
}

.table-container {

  .tableHeadFilter {
    .row {
      border-bottom: 1px solid #f5f6f7;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 12px 0;
      padding-right: 60px;
      position: relative;
      height: 69px;
      overflow: hidden;
      transition: all .25s ease;

      &.active {
        height: auto;
      }

      .fixelBtn {
        position: absolute;
        top: 12px;
        right: 12px;
        height: 44px;
        display: flex;
        align-items: center;
        width: 50px;
        justify-content: space-between;
        color: #666;
        transition: all .25s ease;
        cursor: pointer;

        &:hover {
          color: #3399FF;
        }
      }

      .label {
        flex: 0 0 auto;
        margin-right: 12px;
        font-size: 16px;
        color: #000;
        display: flex;
        align-items: center;
      }

      .filters {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .filter {
          flex: 0 0 auto;
          margin: 6px 12px;
          font-size: 16px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          padding: 0 6px;
          // border-radius: 10px;
          cursor: pointer;

          &.active {
            background: @primary-color;
            color: #fff;
          }
        }
      }
    }

    .search {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .filter {
        min-width: 120px;
        margin-right: 12px;
      }

      .filter-btn {
        margin-right: 12px;

        &:last-child {
          margin: 0;
        }
      }
    }


  }

  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 850px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>